import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  showToast: boolean = false;
  toastMessage: string = '';
  toastType!: string;

  constructor() {}

  show(type: 'success' | 'error' | 'warning', message: string) {
    this.showToast = true;
    this.toastMessage = message;
    this.toastType = type;
    setTimeout(() => {
      this.hide();
    }, 3000); // Hide toast after 3 seconds
  }

  hide() {
    this.showToast = false;
    this.toastMessage = '';
  }
}
