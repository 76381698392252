import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  accessToken: any = null;
  slug: any;
  role: any;
  firstName: any;
  image: any;
  provider: any;

  constructor() {
    this.accessToken = sessionStorage.getItem('key');
  }

  setSessionStorage(res: any) {
    this.accessToken = res.access_token;
    this.slug = res.data.slug;
    this.role = res.data.role;
    this.firstName = res.data.first_name;
    this.image = res.data.image;
    this.provider = res.data.provider;

    sessionStorage.setItem('key', this.accessToken);
    sessionStorage.setItem('slug', this.slug);
    sessionStorage.setItem('role', this.role);
    sessionStorage.setItem('firstName', this.firstName)
    if(this.image){
      sessionStorage.setItem('image', this.image);}
    if(this.provider){
      sessionStorage.setItem('provider', this.provider);
    }
  }

  setSessionStorageUserName(data: any) {
    this.firstName = data;
    sessionStorage.setItem('firstName', this.firstName);
  }

  setSessionStorageProfileImage(imageUrl: any) {
    this.image = imageUrl;
    sessionStorage.setItem('image', this.image);
  }

  clearToken() {
    sessionStorage.removeItem('key');
    sessionStorage.removeItem('slug');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('image');
    this.accessToken = null;
    this.slug = null;
    this.role = null;
  }

  isAuthenticatedUser() {
    if (this.accessToken) {
      return true;
    }
    return false;
  }
}
