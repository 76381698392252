import { Component, HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationEnd } from '@angular/router';
import { SharedUserService } from 'src/app/services/shared/shared-user.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  activeTab = 'dashboard';
  userMenu = false;
  admin = false;
  isOpen: boolean = false;
  currentYear: number = 2023;
  profile_image: any;
  firstName: any;
  firstChar: any;

  constructor( private router: Router, private authService: AuthService , private sharedService: SharedUserService, private oidcSecurityService: OidcSecurityService) {
    this.sharedService.getUserProfileImage().subscribe((imageData: any) => {
      this.profile_image = imageData;
    });
    this.sharedService.getUserProfileName().subscribe((data: any) => {
      this.firstChar = data[0];
    });
   }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTab();
      }
    });
    // Initially, set the active tab based on the current route
    this.updateActiveTab();
    const role = sessionStorage.getItem('role');
    if (role === 'Admin') {
      this.admin = true;
    }
    // this.currentYear = new Date().getFullYear();
    this.profile_image = sessionStorage.getItem('image');
    this.firstName = sessionStorage.getItem('firstName');
    this.firstChar = this.firstName[0]
  }

  updateActiveTab(): void {
    const urlSegments = this.router.url.split('/');
    const currentRoute = urlSegments[urlSegments.length - 1];
    if (currentRoute) {
      // Only change the active tab if a valid route segment is detected
      if(currentRoute === 'dashboard') {
        this.setActiveTab('dashboard');
      } else {
        this.setActiveTab(currentRoute);
      }
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  openUserMenu(): void {
    this.userMenu = !this.userMenu;
  }

  openMenu(val: boolean) {
    this.isOpen = val;
  }

  // Listen for clicks on the document
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    // Check if the click target is not inside the user menu
    if (!this.isClickInsideUserMenu(event)) {
      this.userMenu = false; // Close the user menu
    }
  }

  // Helper function to check if the click is inside the user menu
  isClickInsideUserMenu(event: MouseEvent): boolean {
    const userMenuButton = document.getElementById('user-menu-button');
    const userMenu = document.querySelector('#user-menu');
    if (userMenuButton && userMenu && event.target instanceof Node) {
      return userMenuButton.contains(event.target) || userMenu.contains(event.target);
    }
    return false;
  }

  logout() {
    // Clear session storage
    this.authService.clearToken();
    let provider = this.authService.provider;
    if (provider == 'google') {
      this.oidcSecurityService.revokeAccessToken().subscribe((res: any) => {
        sessionStorage.clear();
        this.router.navigate(['/login']);
      })
      this.oidcSecurityService.logoff().subscribe((result) => {
        sessionStorage.clear();
        this.router.navigate(['/login']);
      });
    } else {
      sessionStorage.clear();
      this.router.navigate(['/login']);
    }
  }
}
