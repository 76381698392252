import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Digital-Business-Card';
  activeUrl: any;
  isHomeRoute: boolean = false; // Initialize it to true by default

  constructor(private router: Router) { }

  ngOnInit() {
    initFlowbite();
    // Subscribe to the NavigationEnd event to get the active URL
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeUrl = event.url;

        // Check if the active URL starts with "/home"
        if (this.activeUrl.startsWith('/home')) {
          this.isHomeRoute = true;
        } else {
          this.isHomeRoute = false;
        }
      }
    });
  }
}