import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appResetField]'
})
export class ResetFieldDirective {

  private defaultValue: string;

  constructor(private el: ElementRef) {
    this.defaultValue = this.el.nativeElement.getAttribute('data-default-value');
  }

  @HostListener('input')
  onInput() {
    if (this.el.nativeElement.value === this.defaultValue) {
      this.el.nativeElement.value = ''; // Clear the input field
    }
  }

}
